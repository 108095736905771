@import 'common/common.scss';

/////////////////
// Placeholder //
/////////////////
.placeholder {
    display: flex;
    justify-content: center;
    align-items: center;

    &_bg_primary {
        background-color: var(--utmb-color-ws-primary);

        & svg {
            fill: var(--utmb-color-ws-secondary);
        }
    }

    &_bg_secondary {
        background-color: var(--utmb-color-ws-secondary);

        & svg {
            fill: var(--utmb-color-ws-primary);
        }
    }
}

/////////////////////
// Skeleton //
/////////////////////
.skeleton {
    border-radius: rem(4px);
    position: relative;
    background-color: var(--utmb-color-gray-hover);
    overflow: hidden;
    margin-right: rem(10px);

    &::after {
        display: block;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        transform: translateX(-100%);
        background: linear-gradient(90deg, var(--utmb-color-gray-hover), var(--utmb-color-white-80), var(--utmb-color-gray-hover));
        animation: loader 1.2s infinite;
    }
}

@keyframes loader {
    100% {
        transform: translateX(100%);
    }
}
