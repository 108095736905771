@import 'common/common.scss';

.button {
    @extend .button-base;
    display: flex;
    flex-wrap: nowrap;
    padding: rem(20px) rem(30px);
    text-transform: uppercase !important;
    box-shadow: 0 0 rem(2px) rgba(0, 0, 0, 0);
    transition: box-shadow 0.2s;
    //
    height: 100%;
    width: 100%;
    min-width: rem(180px);

    &:hover {
        cursor: pointer;
        box-shadow: 0 0 rem(15px) rgba(0, 0, 0, 0.5);
        transition: box-shadow 0.2s;
    }

    &_disabled {
        &:hover {
            cursor: not-allowed;
            box-shadow: none !important;
            transition: none !important;
        }
    }

    svg {
        margin-left: rem(20px);
    }
}

@media (max-width: 768px) {
    .button {
        min-width: rem(100px);
    }
}
