@use 'sass:math';
@import 'common/common.scss';

$padding-left: rem(50px) !default;
$padding-right: rem(10px) !default;

.container {
    background-color: var(--utmb-color-white);
    box-shadow: rem(-5px) rem(10px) rem(20px) rgb(0 0 0 / 25%);
    color: var(--utmb-color-ws-primary);
    font-family: var(--utmb-font-futura-book);
    opacity: 0;
    padding: rem(25px) 0 rem(25px) $padding-left;
    position: absolute;
    right: 0;
    top: var(--top-bar-height);
    transition: opacity 0.3s;
    visibility: hidden;
    width: rem(320px);

    &.not_logged {
        padding: 0;
        text-align: center;
    }

    &.is_open {
        opacity: 1;
        visibility: visible;
    }

    .separator {
        margin: rem(18px) 0;
    }

    .separator_not_logged {
        padding: 0 math.div($padding-left, 2);
    }

    .loading_container {
        position: relative;
        width: 100%;
        min-height: rem(50px);
    }

    .register_container,
    .login_container {
        position: relative;

        &::before {
            background-color: var(--utmb-color-ws-primary);
            content: '';
            height: 100%;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            transition: opacity 0.3s;
            width: rem(5px);
        }

        &:focus::before,
        &:hover::before {
            opacity: 1;
        }
    }

    .register_container {
        padding-bottom: rem(40px);
        padding-top: rem(40px);
    }

    .login_container {
        padding-bottom: rem(22px);
        padding-top: rem(20px);
    }

    .links_list {
        list-style: none;
        margin: 0;
        padding: 0;

        .links_item_active,
        .links_item {
            &:not(:last-child) {
                margin-bottom: rem(10px);
            }

            color: var(--utmb-color-ws-primary);
            display: block;
            font-size: rem(16px);
            height: rem(22px);
            line-height: rem(22px);
            padding-right: $padding-right;
            position: relative;

            &::before {
                background-color: var(--utmb-color-ws-primary);
                content: '';
                height: 100%;
                left: -$padding-left;
                opacity: 0;
                position: absolute;
                transition: opacity 0.3s;
                width: rem(5px);
            }

            &_active::before,
            &:focus::before,
            &:hover::before {
                opacity: 1;
            }
        }
    }

    .login_button {
        display: inline-flex;
    }

    .register_button {
        & > div {
            display: inline-flex;
            padding: rem(20px) rem(35px);
        }

        span {
            font-size: rem(12px);
        }
    }

    .logout_link {
        align-items: center;
        color: var(--utmb-color-blue-light);
        display: flex;
    }

    .preferences {
        padding: 1rem 0;
        >div {
            text-align: center;
        }
        .pref_line {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            padding: 0.5rem;
        }
    }
}
