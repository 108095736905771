@import 'common/common.scss';

.container {
    display: flex;
    align-items: center;
    margin: 0 calc(var(--bs-gutter-x) * -1);
    padding: 0 rem(18px);
    min-height: rem(45px);
    text-transform: uppercase;
    border-bottom: rem(1px) solid var(--utmb-color-white);

    .button {
        flex: 1;
        text-align: center;
        text-decoration: none;

        svg {
            margin: 0 rem(15px);
        }
    }

    button.button {
        display: flex;
        min-height: auto;
        border: none;
        background: none;
        justify-content: center;
        align-items: center;
        letter-spacing: rem(1px);

        svg {
            margin-top: rem(5px);
        }
    }

    .separator {
        margin-left: 0;
        margin-right: rem(25px);
        width: rem(1px);
        height: 100%;
        background-color: var(--utmb-color-secondary);
        transform: skew(-24deg);
        transform-origin: bottom left;
    }
}
