@import 'common/common.scss';

.menu_item_container {
    text-align: center;

    .menu_item_link {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-decoration: none !important;
    }

    .menu_item_label {
        margin-top: rem(3px);
        font-size: rem(10px);
        font-weight: 700;
        font-family: 'Oxanium';
        color: var(--utmb-color-default);
    }
}
