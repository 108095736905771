@import 'common/common.scss';

.separator {
    margin: rem(20px) 0;
}

.skeleton_user_information {
    align-items: center;
    display: flex;

    .skeleton_image_container {
        border-radius: 50%;
        margin-right: rem(10px);
        overflow: hidden;
        width: rem(80px);
    }

    .skeleton_content {
        width: calc(100% - rem(100px));

        .skeleton_name {
            margin-bottom: rem(10px);
        }
    }
}

.skeleton_btn_wrapper {
    display: flex;
    justify-content: center;
    width: 90%;
}
