@import '../../styles/common/common.scss';

// ----------------------------------------------------------------------------

// Default Styles

.button {
    @extend .button-base;

    @include hover-focus {
        filter: brightness(1.2);
    }

    &:focus {
        outline: auto;
    }

    &:disabled {
        filter: none !important;
    }

    &.activeLink {
        color: var(--utmb-color-ws-secondary);
    }
}

// ----------------------------------------------------------------------------

// Alternative Styles

.ghost {
    background-color: var(--quiet) !important;
    border-color: var(--quiet);
    padding: 0;
}
