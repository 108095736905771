@import '../../../../styles/common/common.scss';

/* MOBILE SCROLLABLE Y CONTAINER */
.scrollable {
    background-color: var(--utmb-color-primary);
    color: var(--utmb-color-white);
    flex: 1;
    justify-content: space-between;
    height: calc(100vh - rem(53px));
    overflow: auto;

    button {
        min-height: rem(44px);
        letter-spacing: 1.3px;
        font-size: inherit;
        text-align: left;
        color: var(--utmb-color-white) !important;
    }
    h3 {
        font-weight: normal !important;
        font-size: inherit;
        button {
            & > :first-child {
                width: 28px;
                max-height: 24px;
                max-width: 28px;
                display: inline-block;
            }
            & > :nth-child(2) {
                margin-left: rem(12px);
            }
        }
    }
    [data-reach-accordion-panel]:focus {
        // Please forgive us gods of the internet
        outline: none;
    }
    svg {
        fill: currentColor;
    }
}
/* WRAPPER TO ADD */

.container {
    overflow-x: hidden;
    width: 100%;
    padding: 0 var(--bs-gutter-x);

    button:active, button:target {
        background: rgba(255,255,255,0.5) !important;
        width: 100%;
    }
}

/* MOBILE BOTTOM BAR */
.bar {
    background-color: var(--utmb-color-white);
    height: var(--mobile-bar-height);
    padding: 0 rem(12px);
    display: flex;
    align-items: center;
    box-shadow: 0 0 rem(15px) rgba(0, 0, 30, 0.2);
    &.fixed {
        @include bp(lg) {
            display: none;
        }

        position: fixed;
        bottom: 0;
        z-index: var(--utmb-zindex-bottom-nav);
    }

    a,
    button {
        min-height: var(--mobile-bar-height) !important;
        height: var(--mobile-bar-height) !important;
    }
    a {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &.hidden {
        z-index: -10;
        display: none;
    }
}

/* Accordion button row */

.row {
    background-color: transparent;
    border: rem(1px) solid transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100% !important;
    line-height: rem(44px);
    font-size: rem(24px) !important;

    @include active-hover-focus {
        background-color: transparent;
        border: rem(1px) solid transparent;
        // Please forgive us gods of the internet
        outline: none;
    }

    & > *:last-child {
        margin-left: auto;
        width: auto !important;
    }

    svg + span {
        margin-left: rem(12px);
    }

    .emptyIcon {
        width: 22px;
        height: 22px;
        background-color: transparent;
    }

    span {
        flex: 1;
        margin-left: rem(30px);
        font-size: inherit;
        text-align: left;
        &.spaced {
            margin: 0 rem(12px);
        }
    }
}
/* Accordion Panel*/
.separator {
    margin: rem(18px) 0;
}
.panel {
    display: flex;
    flex-direction: column;
}
.panel_simple {
    padding-left: rem(28px);
}

/* NonAccordion row */
.generic {
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
    text-align: left;

    button {
        padding: 0;
        width: 100% !important;
        /*width: 70% !important;
        max-width: 70% !important;*/
        min-height: rem(44px);
        display: flex;

        span:last-child {
            margin-left: rem(12px);
        }
        & > :first-child {
            width: 28px;
            max-height: 24px;
            max-width: 28px;
            display: inline-block;
        }
    }

    svg + span {
        margin-left: rem(12px);
    }
}

.header {
    position: relative;
    margin-top: rem(52px);

    &_not_logged {
        margin-bottom: rem(65px);
    }

    .logo_container {
        margin: auto;
        width: rem(85px);
    }

    /* Language selector */
    .selector {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        text-transform: uppercase;

        &:first-child{
            right: auto;
            left: 0;
        }

        [data-reach-menu-button] {
            text-transform: uppercase;
            padding: 0 0 0 rem(8px);
        }
        [data-reach-menu],
        [data-reach-menu-popover] {
            position: absolute;
            top: calc(44px + rem(4px)) !important;
            z-index: 99;
        }
        [data-reach-menu-popover] {
            background-color: var(--utmb-color-blue);
            border: rem(1px) solid var(--utmb-color-white);
            width: auto;
        }
        [data-reach-menu-item] {
            padding: 0 rem(8px);
            line-height: rem(44px);
            white-space: nowrap;

            &:not(:last-of-type) {
                border-bottom: rem(1px) solid var(--utmb-color-white);
            }
        }
    }
}

.utmbws {
    position: relative;
    height: 8rem;
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    > span {
        max-height: 5rem;
        top: 50% !important;
        transform: translateY(-50%);
        width: 8rem!important;
    }
}

.flex-start {
    justify-content: flex-start !important;
}

.social {
    padding: rem(12px) 0 rem(24px) 0;
    > *:not(:first-of-type) {
        margin-left: rem(18px);
    }
}

.menu_user {
    &_logout {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &_logout_link {
        color: var(--utmb-color-blue-light);
    }
}
