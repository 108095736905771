@import 'common/common.scss';

@mixin live-banner-colors($color: 'primary') {
    @if ($color == 'primary') {
        &::before {
            background: linear-gradient(to left, transparent, var(--utmb-color-ws-primary));
        }
        &::after {
            background: linear-gradient(to right, transparent, var(--utmb-color-ws-primary));
        }
    } @else if ($color == 'none') {
        &::before {
            background: linear-gradient(to left, transparent, var(--utmb-color-category-100m));
        }
        &::after {
            background: linear-gradient(to right, transparent, var(--utmb-color-ws-primary));
        }
    }
}

.root {
    @include live-banner-colors('none');

    background: linear-gradient(to right, var(--utmb-color-category-100m), var(--utmb-color-ws-primary));
    color: var(--utmb-color-white);

    align-items: center;
    display: flex;
    flex-direction: column;
    height: var(--top-bar-height);
    justify-content: flex-start;
    overflow: hidden;
    position: relative;
    text-transform: uppercase;
    z-index: 1;

    &::before,
    &::after {
        content: '';
        display: block;
        width: rem(20px);
        top: 0;
        pointer-events: none;
        position: absolute;
        height: 100%;
        z-index: 10;
    }
    &::before {
        left: 0;
    }
    &::after {
        right: 0;
    }

    @include bp(lg) {
        @include live-banner-colors();
    }
}
