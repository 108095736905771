@import 'common/common.scss';

$animation-duration: 1000ms !default;
$visibility-duration: 5000ms !default;

/* DO NOT REMOVE THE div PART */
/* Used to override a third party CSS rule */
div.marquee_container {
    overflow: unset !important;
    transition: all ease-in-out $animation-duration;

    [class='marquee'] {
        height: var(--top-bar-height);

        a {
            text-decoration: none;
            padding-right: rem(50px);
        }

        .live_icon {
            margin-left: rem(8px);
        }
    }
}

:export {
    animationDuration: $animation-duration;
    visibilityDuration: $visibility-duration;
}
