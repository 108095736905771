.toolbar {
    display: flex;
    width: 100%;
}

.toolbar > div,
.toolbar > button,
.toolbar > a {
    flex: 1;
    position: relative;
    font: inherit;
    align-self: stretch;
}
