@import '../../styles/common/common.scss';

.footer_container {
    width: 100%;
    height: auto;
    padding: rem(50px) 0 rem(110px);
    background-color: var(--utmb-color-primary);
    color: var(--utmb-color-white);
    position: relative;
    overflow-x: hidden;

    @include bp(md) {
        min-height: rem(410px);
    }

    @include bp(lg) {
        padding: rem(100px) 0 rem(40px);
    }

    .separator {
        position: absolute;
        bottom: 28%;
        left: rem(-240px);
        width: 100vw;
        height: rem(1px);
        background-color: var(--utmb-color-white);
        opacity: 0.1;
    }

    .footer_W_logo {
        position: absolute;
        width: rem(210px);
        height: rem(240px);
        right: 0;
        bottom: 12%;
        opacity: 1;
    }

    .footer_bg_image {
        opacity: 0.5;
    }

    .footer_row {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        padding-top: 4rem;

        @include bp(lg) {
            flex-direction: row;
        }

        .footer_logo_wrapper {
            margin-top: rem(20px);
            width: 100%;
            min-height: rem(130px);
            position: relative;

            @include bp(lg) {
                margin-top: 0;
                margin-right: rem(100px);
                width: rem(150px);
                transform: translateY(rem(-17px));
            }
        }

        .footer_nav {
            margin-top: rem(85px);
            width: 100%;

            @include bp(md) {
                display: flex;
                flex: 1;
                flex-direction: row;
                flex-wrap: wrap;
            }

            @include bp(lg) {
                margin-top: 0;
            }
        }
    }

    .footer_nav_column {
        margin-bottom: rem(50px);
        width: 100%;

        &:last-child {
            margin-bottom: 0;
        }

        @include bp(md) {
            margin-right: rem(30px);

            &:last-child {
                margin-right: 0;
            }
        }

        @include bp(lg) {
            margin-bottom: rem(60px);
        }

        @include bp(xl) {
            margin-right: rem(40px);
        }

        .nav_line {
            font-size: rem(16px);
            position: absolute;
            top: rem(-40px);
        }

        .footer_nav_link {
            &:link,
            &:visited,
            &:hover,
            &:focus,
            &:active {
                text-decoration: none;
                margin: rem(10px) 0;
            }

            &:hover {
                text-decoration: underline;
            }
        }

        .footer_social_media {
            margin: rem(10px) 0;

            .footer_social_media_link {
                margin-right: rem(25px);

                &:last-child {
                    margin-right: 0;
                }

                svg {
                    width: rem(21px);
                    height: rem(21px);
                }
            }
        }
    }

    .footer_nav_column_copyright {
        @extend .footer_nav_column;

        @include bp(md) {
            @media (orientation: landscape) {
                width: auto !important;
                max-width: unset !important;
            }
        }
    }

    .footer_bottom_row {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        @include bp(md) {
            flex-direction: row;
            justify-content: flex-start;
        }

        .footer_nav_column {
            flex: 1;
            margin-bottom: rem(25px);

            &:last-child {
                margin-bottom: 0;
            }

            @include bp(lg) {
                margin-bottom: 0;
                text-align: center;
            }
        }

        a {
            &:link,
            &:visited,
            &:hover,
            &:focus,
            &:active {
                text-decoration: none;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.footer_custom_spacer {
    min-height: rem(50px) !important;

    @include bp(md) {
        min-height: rem(60px) !important;
    }
}
