@import 'common/common.scss';

.user_information {
    display: flex;
    margin-top: rem(20px);

    @include bp(sm) {
        margin-top: 0;
    }

    .content {
        display: flex;
        justify-content: center;
        flex-direction: column;

        a {
            display: inline-block;
            margin-top: rem(5px);
            color: var(--utmb-color-white) !important;

            @include bp(md) {
                color: var(--utmb-color-blue-light) !important;
            }
        }
    }

    .placeholder,
    .image {
        border-radius: 100%;

        &_container {
            position: relative;
            margin-right: rem(20px);
            width: rem(70px);
            height: rem(70px);
            min-width: rem(70px);
        }
    }
}
