@import '../../styles/common/common.scss';


@keyframes meganavslideon {
    from {
        display: block;
        transform: translateY(rem(-50px)); 
        opacity: 0;
    }
    to {
        display: block;
        transform: translateY(0); 
        opacity: 1;
    }
}

.root {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: var(--utmb-zindex-max);
    height: 100vh;
    background-color: var(--utmb-color-ws-primary);
    color: var(--utmb-color-white);
    overflow: auto;
    display: none;
    transform: translateY(rem(-50px));
    opacity: 0;

    &.show {
        animation: meganavslideon 0.6s;
        display: block;
        opacity: 1;
        transform: translateY(0);
    }

    .container {
        padding-top: rem(50px);

        .heading {
            padding: rem(50px) 0;

            .heading_button {
                display: flex;
                justify-content: center;
                font-size: rem(50px);

                svg {
                    width: rem(40px);
                    height: rem(40px);
                    cursor: pointer;
                }
            }

            .heading_button_wrapper {
                display: flex;
                height: fit-content;
                cursor: pointer;
            }

            .title {
                text-transform: uppercase;
            }

            .summary {
                width: 50%;
            }
        }

        .region_section {
            padding: rem(50px) 0;

            .region_name {
                color: var(--utmb-color-ws-secondary);
                text-transform: uppercase;
                text-align: center;
                transform: rotate(-90deg);
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .image_wrapper {
                height: rem(150px);
                padding: 0;
                overflow: hidden;
                display: flex;
                position: relative;
                cursor: pointer;

                .placeholder {
                    height: 100%;
                }

                .race_link {
                    position: relative;
                    width: 100%;
                    height: 100%;

                    @include active-hover-focus {
                        .overlay {
                            background-color: rgb(0 13 68 / 60%);

                            .date,
                            .flag {
                                opacity: 1;
                            }

                            .utmb_icon {
                                height: rem(90px);
                                width: rem(100px);
                            }
                        }

                        .image {
                            filter: grayscale(1) !important;
                            transform: scale(1.1);
                        }
                    }
                }

                .overlay {
                    background-color: transparent;
                    z-index: 1;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;

                    .date,
                    .flag {
                        width: rem(65px);
                        opacity: 0;
                        transition: opacity 0.3s;
                    }
                    .date {
                        white-space: pre-wrap;
                    }

                    .utmb_icon {
                        position: relative;
                        height: rem(120px);
                        width: rem(165px);
                        transition: 0.3s;
                    }

                    .flag {
                        display: flex;
                        justify-content: center;

                        span {
                            width: rem(40px);
                            height: rem(30px);
                            box-shadow: 0 0 rem(30px) rgba(0 0 0 / 75%);
                        }
                    }
                }

                .image {
                    width: 100%;
                    height: auto;
                    filter: brightness(0.7) !important;
                    transition: transform 0.3s;
                }
            }
        }
    }
}

.root_mobile {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    min-height: 100vh;
    padding-bottom: rem(100px);
    color: var(--utmb-color-white);
    background-color: var(--utmb-color-ws-primary);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;

    &.show {
        opacity: 1;
        visibility: visible;
    }

    .container_mobile {
        padding-top: rem(50px);

        .row {
            [data-reach-accordion-item] {
                border-top: rem(1px) solid var(--utmb-color-ws-secondary);
                padding: rem(40px) 0;
            }

            &:last-child {
                [data-reach-accordion-item] {
                    border-bottom: rem(1px) solid var(--utmb-color-ws-secondary);
                }
            }
        }

        .close {
            display: flex;
            margin-left: auto;
            min-height: auto;
            border: 0;
            background: none;
        }

        .title {
            letter-spacing: 1.5px;
        }

        [data-reach-accordion-button] {
            color: var(--utmb-color-ws-secondary);
            background-color: transparent;
            border: 0;
            cursor: pointer;
            position: relative;
            text-align: left;
            width: 100%;

            .svg {
                position: absolute;
                right: rem(2px);
                transform: translate3d(0, -50%, 0) scale(2) rotate(90deg);
                top: 50%;
            }
        }

        [data-reach-accordion-item][data-state='open'] {
            [data-reach-accordion-button] {
                .svg {
                    transform: translate3d(0, -50%, 0) scale(2) rotate(-90deg);
                }
            }
        }

        [data-reach-accordion-panel] {
            line-height: rem(24px);
            margin-top: rem(20px);

            .image_wrapper_mobile {
                height: rem(150px);
                margin: 0 rem(-30px);
                padding: 0;
                overflow: hidden;
                display: flex;
                position: relative;
                cursor: pointer;

                .placeholder {
                    height: 100%;
                }

                .overlay {
                    background-color: transparent;
                    z-index: 1;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;

                    .utmb_icon {
                        color: white !important;
                        position: relative;
                        height: rem(50px);
                    }

                    .date,
                    .utmb_icon,
                    .flag {
                        width: rem(65px);
                    }

                    .flag {
                        display: flex;
                        justify-content: center;

                        span {
                            width: rem(40px);
                            height: rem(30px);
                            box-shadow: 0 0 rem(30px) rgba(0 0 0 / 75%);
                        }
                    }
                }

                .image {
                    width: 100%;
                    height: auto;
                    filter: brightness(0.7) !important;
                }
            }
        }
    }
}
