@import '../../../../styles/common/common.scss';

.top_nav_wrapper {
    color: var(--utmb-color-white);
    background-color: var(--utmb-color-ws-primary);
    z-index: var(--utmb-zindex-top-nav);
    display: none;
    width: 100%;
    position: fixed;
    top: 0;
    margin-left: 0;

    @include bp(sm) {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: nowrap;

        &>div {
            width: auto;
            margin: 0;
            padding: 0;
            
            &:nth-child(2) {
                flex-grow: 2;
                width: 20%;
                padding: 0 12px;
            }
        }
    }


    .left_content_wrapper {
        padding-left: rem(20px);
        height: var(--top-bar-height);
        display: flex;
        align-items: center;

        .utmb_link {
            overflow: hidden;
            display: flex;
            align-items: center;

            .logo {
                transform: translateY(rem(2px));
                margin-right: rem(10px);
            }

            a {
                text-decoration: none;
            }

            .event_name {
                text-transform: uppercase;
            }
        }

        .separator {
            // As the separator is inclined on the right, the margin right
            // must be higher than the left one as we need to consider the
            // width of th separator.
            margin-left: rem(15px);
            margin-right: rem(40px);
            width: rem(2px);
            height: 100%;
            background-color: var(--utmb-color-ws-secondary);
            transform: skew(-24deg);
            transform-origin: bottom left;
        }

        .mega_nav_link {
            transform: translatey(rem(2px));
            display: flex;
            align-items: center;
            cursor: pointer;

            svg {
                margin-left: rem(15px);
            }
        }
    }

    .right_content_wrapper {
        height: var(--top-bar-height);
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .selector {
            text-transform: uppercase;
            z-index: var(--utmb-zindex-over-nav);
            padding: 0 rem(20px) 0 0;

            &:first-child {
                padding: 0 rem(20px);
            }

            [data-reach-menu-button] {
                text-transform: uppercase;
                padding-left: rem(8px);
                min-width: rem(50px);
                height: var(--top-bar-height);
                cursor: pointer;

                svg {
                    margin-left: rem(5px);
                }
            }
            [data-reach-menu],
            [data-reach-menu-popover] {
                position: absolute;
                min-width: rem(56px);
                top: var(--top-bar-height) !important;
                z-index: var(--utmb-zindex-over-nav) !important;
                background-color: var(--utmb-color-gray);
                box-shadow: 0 rem(7px) rem(20px) rgba(0, 0, 0, 0.2);
            }

            [data-reach-menu-item] {
                padding-right: rem(10px);
                padding-left: rem(10px);
                min-width: rem(56px);
                height: var(--top-bar-height);
                line-height: var(--top-bar-height);
                background-color: var(--utmb-color-ws-primary);
                white-space: nowrap;
                min-height: auto;

                @include hover-focus {
                    background-color: var(--utmb-color-ws-secondary);
                }

                &[data-selected] {
                    background-color: var(--utmb-color-ws-secondary);
                }

                &:not(:last-of-type) {
                    border-bottom: rem(1px) solid var(--utmb-color-secondary);
                }
            }
        }

        .topNav_dashboard {
            position: relative;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 rem(20px);
            line-height: var(--top-bar-height);
            // border-left: rem(1px) solid var(--utmb-color-border-gray);
            color: var(--utmb-color-ws-primary);
            background-color: var(--utmb-color-ws-secondary);
            cursor: pointer;

            @media (max-width: 768px) {
                overflow: hidden;
            }

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: rem(-25px);
                z-index: -1;
                height: calc(var(--top-bar-height) + rem(10px));
                background-color: var(--utmb-color-ws-secondary);
                transform: skew(-24deg);
                transform-origin: top left;
            }

            svg {
                margin-left: rem(10px);
                width: rem(8px);
                height: rem(14px);
            }

            .svg_chevron {
                margin-left: rem(15px);
                width: rem(6px);
                height: rem(10px);
            }
        }
    }
}

.bar {
    display: none;
    align-items: center;
    margin: 0 auto;
    padding-left: rem(25px);
    width: 100%;
    height: var(--top-bar-height);

    @include bp(sm) {
        display: flex;
    }

    a {
        color: var(--utmb-color-white);
    }

    /* UTMB WORLD LINK */
    & > *:first-child {
        display: flex;
        align-items: center;
        width: auto;
        text-align: center;
        padding: 0;
    }

    .event_name {
        text-transform: uppercase;
    }

    .dropdown {
        position: relative;
        width: auto;
        z-index: var(--utmb-zindex-over-nav);

        [data-reach-menu-button] {
            text-transform: uppercase;
            width: 100%;
            // min-width: rem(160px);
            height: var(--top-bar-height);
            cursor: pointer;

            span + div {
                width: auto !important;
            }

            span {
                margin-right: rem(15px);
            }
        }

        [data-reach-menu],
        [data-reach-menu-popover] {
            position: absolute;
            top: var(--top-bar-height) !important;
            z-index: var(--utmb-zindex-over-nav) !important;
            background-color: var(--utmb-color-gray);
            box-shadow: 0 rem(7px) rem(20px) rgba(0, 0, 0, 0.2);
            white-space: nowrap;
        }

        [data-reach-menu-item] {
            padding: 0 rem(20px);
            line-height: var(--top-bar-height);
            background-color: var(--utmb-color-ws-primary);

            @include hover-focus {
                background-color: var(--utmb-color-ws-secondary);
            }

            &[data-selected] {
                background-color: var(--utmb-color-ws-secondary);
            }

            &:not(:last-of-type) {
                border-bottom: rem(1px) solid var(--utmb-color-ws-secondary);
            }
        }
    }

    .selector {
        text-transform: uppercase;
        padding: 0 rem(20px);
        z-index: var(--utmb-zindex-over-nav);

        [data-reach-menu-button] {
            text-transform: uppercase;
            padding-left: rem(8px);
            width: rem(50px);
            height: var(--top-bar-height);
            cursor: pointer;
        }
        [data-reach-menu],
        [data-reach-menu-popover] {
            position: absolute;
            width: rem(56px);
            top: var(--top-bar-height) !important;
            z-index: var(--utmb-zindex-over-nav) !important;
            background-color: var(--utmb-color-gray);
            // border-top: rem(1px) solid var(--utmb-color-border-gray);
            box-shadow: 0 rem(7px) rem(20px) rgba(0, 0, 0, 0.2);
        }

        [data-reach-menu-item] {
            padding-left: rem(10px);
            width: rem(56px);
            height: var(--top-bar-height);
            line-height: var(--top-bar-height);
            background-color: var(--utmb-color-ws-primary);

            @include hover-focus {
                background-color: var(--utmb-color-ws-secondary);
            }

            &[data-selected] {
                background-color: var(--utmb-color-ws-secondary);
            }

            &:not(:last-of-type) {
                border-bottom: rem(1px) solid var(--utmb-color-secondary);
            }
        }
    }

    .logo {
        margin-right: rem(15px);
        width: rem(24px);
    }

    .topNav_dashboard {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 rem(20px);
        line-height: var(--top-bar-height);
        // border-left: rem(1px) solid var(--utmb-color-border-gray);
        color: var(--utmb-color-ws-primary);
        background-color: var(--utmb-color-ws-secondary);
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: rem(-25px);
            z-index: -1;
            height: calc(var(--top-bar-height) + rem(10px));
            background-color: var(--utmb-color-ws-secondary);
            transform: skew(-24deg);
            transform-origin: top left;
        }

        svg {
            margin-left: rem(10px);
            width: rem(8px);
            height: rem(14px);
            margin-top: rem(-3px);
        }

        .svg_chevron {
            margin-left: rem(15px);
            width: rem(6px);
            height: rem(10px);
        }
    }

    .separator {
        // As the separator is inclined on the right, the margin right
        // must be higher than the left one as we need to consider the
        // width of th separator.
        margin-left: rem(15px);
        margin-right: rem(40px);
        width: rem(1px);
        height: 100%;
        background-color: var(--utmb-color-ws-secondary);
        transform: skew(-24deg);
        transform-origin: bottom left;
    }

    .mega_nav_link {
        display: inline-flex;
        align-items: center;
        cursor: pointer;

        svg {
            margin-left: rem(15px);
        }
    }
}
