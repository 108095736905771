@import 'common/common.scss';

.preferences {
    
    div[data-disabled][data-reach-menu-item]:hover {
        cursor: default;
        background: var(--utmb-color-ws-primary) !important;
    }

}
