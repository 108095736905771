@import '../../styles/common/common.scss';
// ----------------------------------------------------------------------------

.link_container {
    display: flex;
}

.link_container_start {
    justify-content: start;
}

.link_container_center {
    justify-content: center;
}

.link_container_end {
    justify-content: flex-end;
}
// Link

.link {
    // -webkit-appearance: none;
    cursor: pointer;
    display: inline;
    font: inherit;
    text-decoration: underline;
    max-width: auto;
    min-height: 0;
    padding: 0;
    border: 0;
    color: currentColor;
    background: transparent;
    background-color: transparent;
    font: inherit;

    &:focus {
        outline: auto;
    }
}

.link_default {
    height: rem(44px);
    display: flex;
    align-items: center;
    text-underline-offset: rem(8px);
    text-decoration-thickness: rem(1px);
    column-gap: rem(10px);

    &:link,
    &:visited,
    &:active {
        text-underline-offset: rem(8px);
        text-decoration-thickness: rem(1px);
    }

    @include hover-focus {
        text-decoration: underline;
    }

    &.landscape-only {
        display: none !important;

        @media (orientation: landscape) {
            display: flex;
        }
    }
}

.link_default_label {
    &:link,
    &:visited {
        font-weight: normal !important;
    }

    &:active {
        font-weight: normal !important;
    }
}

.link_default_nu {
    &:link,
    &:visited,
    &:focus,
    &:hover,
    &:active {
        text-decoration: none !important;
    }
}

// ----------------------------------------------------------------------------

// Link as Button

.link_button {
    display: inline-flex !important;
    height: auto !important;
    // width: fit-content !important;
    // display: flex;
    width: fit-content !important;
    justify-content: center;
    // box-shadow: 0 0 rem(15px) rgba(0, 0, 0, 0.3);

    &:link,
    &:visited,
    &:focus,
    &:hover,
    &:active {
        display: inline-flex !important;
        width: fit-content !important;
        align-items: center;
        text-decoration: none !important;
        box-shadow: 0 0 rem(15px) rgba(0, 0, 0, 0.08);
    }

    @include hover-focus {
        box-shadow: 0 0 rem(15px) rgba(0, 0, 0, 0.5);
        filter: brightness(1.2);
    }
}

.link_button_container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(25px) rem(30px);
    max-width: rem(300px);

    svg {
        margin-left: rem(15px);
    }
}

.link_button_label {
    @extend .link_default_label;
    transform: translate(0, rem(1px));
    letter-spacing: rem(1px);
    text-transform: uppercase !important;
    text-align: center;
}
